/*
 * SonarQube
 * Copyright (C) 2009-2021 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
a.identity-provider-link {
  display: block;
  width: auto;
  line-height: 22px;
  padding: 8px 12px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  box-sizing: border-box;
  background-color: #236a97;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

a.identity-provider-link.small {
  line-height: 14px;
  padding: 4px 8px;
}

a.identity-provider-link:hover,
a.identity-provider-link:focus {
  box-shadow: inset 0 0 0 100px rgba(255, 255, 255, 0.1);
}

a.identity-provider-link.dark-text {
  color: #666;
}

a.identity-provider-link.dark-text:hover,
a.identity-provider-link.dark-text:focus {
  box-shadow: inset 0 0 0 100px rgba(0, 0, 0, 0.1);
}

a.identity-provider-link > img {
  padding-right: 12px;
}

a.identity-provider-link.small > img {
  padding-right: 8px;
}

a.identity-provider-link > span::before {
  content: '';
  opacity: 0.4;
  border-left: 1px #b4b4b4 solid;
  margin-right: 12px;
}
