/*
 * SonarQube
 * Copyright (C) 2009-2021 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.portfolio-overview > h1 {
  font-weight: normal;
}

.portfolio-overview > .page-actions {
  margin-bottom: 0;
}

.portfolio-measure-secondary-value {
  line-height: 24px;
  font-size: 18px;
  font-weight: 300;
}

.portfolio-grid {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.portfolio-grid > li {
  vertical-align: top;
  width: 50%;
  text-align: center;
}

.portfolio-grid > li.text-middle {
  vertical-align: middle;
}

.portfolio-freshness {
  line-height: 24px;
  white-space: nowrap;
}

.portfolio-description {
  max-width: 60%;
}

.portfolio-boxes {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 20px;
  padding: 15px 0;
  width: 100%;
}

.portfolio-box {
  flex: 1 0 10%;
  position: relative;
  padding: 0 16px 66px;
  margin: 0 8px;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  box-sizing: border-box;
}

.portfolio-box:first-child {
  margin-left: 0;
}

.portfolio-box:last-child {
  margin-right: 0;
}

.portfolio-box-title {
  padding: 8px 0 16px;
  margin: 8px 0 16px;
  font-size: 16px;
  line-height: 16px;
  border-bottom: 1px solid #e6e6e6;
  white-space: nowrap;
}

.portfolio-box-title > .button-small > svg {
  margin-top: 0;
}

.portfolio-box > h3 {
  color: #666;
  font-size: 12px;
  font-weight: normal;
  margin-top: 8px;
}

.portfolio-box-rating,
.portfolio-box-rating .rating {
  display: block;
  width: 80px;
  height: 80px;
  line-height: 80px;
}

.portfolio-box-rating {
  margin: 16px auto;
  border: none;
}

.portfolio-box-rating .rating {
  border-radius: 80px;
  font-size: 48px;
  text-align: center;
}

.portfolio-box-rating .rating.no-rating {
  color: #666;
  font-size: 16px;
}

.portfolio-box-links {
  border-top: 1px solid #e6e6e6;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.portfolio-box-links > div {
  display: inline-block;
  padding: 12px 0;
  width: 50%;
  box-sizing: border-box;
}

.portfolio-box-links > div:first-child {
  border-right: 1px solid #e6e6e6;
}

.portfolio-box-links svg,
.portfolio-breakdown-box-link svg {
  vertical-align: middle;
}

.portfolio-breakdown {
  display: flex;
}

.portfolio-breakdown-box {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #e6e6e6;
  margin: 8px 0 16px;
}

.portfolio-breakdown-box + .portfolio-breakdown-box {
  margin-left: 16px;
}

.portfolio-breakdown-box h2,
.portfolio-breakdown-box .portfolio-breakdown-metric {
  margin: 8px 16px 0;
}

.portfolio-breakdown-box-link {
  margin-top: 16px;
  border-top: 1px solid #e6e6e6;
  padding: 16px;
}

.portfolio-sub-components table.data th {
  font-size: 13px;
  text-transform: none;
  vertical-align: middle;
}

.portfolio-sub-components-cell {
  width: 110px;
}

.portfolio-meta-header {
  margin-bottom: 4px;
  color: #333;
}

.portfolio-meta-card {
  min-width: 200px;
  box-sizing: border-box;
}

.portfolio-meta-card + .portfolio-meta-card {
  margin-top: 16px;
  padding-top: 15px;
  border-top: 1px solid #e6e6e6;
}
